.root {
    width: 100%;
    height: 100%;
    padding: 40px;
    background: #F3F3F3;
    overflow: hidden;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.contentWrapper {
    align-self: stretch;
    justify-content: center;
    align-items: flex-start;
    gap: 19px;
    display: inline-flex;
}

.container {
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    display: inline-flex;
}

.title {
    align-self: stretch;
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: 800;
    line-height: 12px;
    word-wrap: break-word;
}

.textContent {
    align-self: stretch;
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
}

.textLink {
    background: none;
    border: none;
    padding: 0;
    color: black;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    line-height: 16px;
    cursor: pointer;
}

.note {
    align-self: stretch;
    text-align: center;
    color: #959595;
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
}

.modalContent {
    padding: 24px;
    max-width: 600px;
}

.modalTitle {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
}

.modalText {
    font-size: 16px;
    line-height: 24px;
}

.refundPolicyWrapper {
    max-width: 640px;
    max-height: 80vh;
    overflow-y: auto;
    padding: 24px;
}

.sellerBanner {
    width: 100%;
    padding: 8px;
    background: #F1F1F2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
}

.sellerBannerText {
    color: #4A4A4F;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}