@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /*

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.aspectRatioWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transitionStyleButton);
  background: white;

  @media (--viewportMedium) {
    &:hover {
      /* Removed transform and box-shadow */
    }
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  /* Center the image */
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 14px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  composes: textSmall from global;

  font-weight: 600;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: .2em;
  line-height: 1.65;
  color: #1c1b1b;

  /* Remove default margins from font */
  margin-top: 2px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
  }
}

.originalPriceValue {
  /* Font */
  composes: textSmall from global;

  font-weight: 400;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: .2em;
  line-height: 1.65;
  color: #1c1b1b;
  text-decoration: line-through;

  /* Remove default margins from font */
  margin-top: 2px;
  margin-bottom: 0;
  padding-left: 10px;

  @media (--viewportMedium) {
    margin-top: 2px;
    margin-bottom: 0;
    padding-left: 10px;
  }
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  color: var(--marketplaceColor);
  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  composes: textSmall from global;

  font-weight: 400;
  font-style: normal;
  font-size: 10.5px;
  letter-spacing: .2em;
  text-transform: uppercase;
  line-height: 1.65;
  color: #1c1b1b;
  margin: 0;
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey700);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.brandName {
  margin-top: 10px;
  margin-bottom: 20px;
}